<template>
  <div class="table">
    <div class="t-title">
      <v-btn
        v-if="props.day"
        @click="setDay('left')"
        :disabled="disabledLeft"
        class="mx-2"
        dark
        small
      >
        <v-icon dark> mdi-arrow-left </v-icon>
      </v-btn>
      <span>{{ getTitle }}</span>
      <v-btn
        v-if="props.day"
        @click="setDay('right')"
        :disabled="disabledRight"
        class="mx-2"
        dark
        small
      >
        <v-icon dark> mdi-arrow-right </v-icon>
      </v-btn>
    </div>
    <v-data-table
      :headers="headers"
      :items="props.items"
      hide-default-footer
      disable-filtering
      disable-pagination
      disable-sort
    >
      <template v-slot:item.rubles="{ item }">
        <div style="text-align: right">
          {{ parseRubles(item.rubles) }}
        </div>
        <div v-if="props.prediction" class="prediction">
          {{ getPrediction(item.rubles) }}
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<!-- MARK: script -->
<script setup>
import { computed, defineEmits } from 'vue';

const emit = defineEmits(['change-day']);

const props = defineProps({
  items: Array,
  title: String,
  day: Date,
  prediction: Boolean,
});

const today = computed(() => new Date().getDate());
const dayDate = computed(() => props.day.getDate());

const getTitle = computed(() => {
  if (props.day) {
    if (today.value - dayDate.value == 1) return 'Вчера';

    return props.day.toLocaleString('ru-RU', { month: 'long', day: 'numeric' });
  }

  return props.title;
});

const disabledLeft = computed(() => dayDate.value == 1);
const disabledRight = computed(() => today.value - dayDate.value == 1);

function setDay(direction) {
  const newDate = new Date(props.day);
  direction = direction == 'left' ? -1 : 1;
  newDate.setDate(newDate.getDate() + direction);

  emit('change-day', newDate);
}

function getPrediction(rubles) {
  const now = new Date();
  const hour = now.getHours();
  const minute = now.getMinutes();
  const seconds = now.getSeconds();

  const time = hour * 3600 + minute * 60 + seconds;

  const value = (rubles / time) * 86400;

  return parseRubles(value);
}

const parseRubles = value => Number(value.toFixed(0)).toLocaleString('ru-RU');

const headers = [
  {
    text: 'Пользователь',
    value: 'user',
  },
  {
    text: 'Доход',
    value: 'rubles',
  },
];
</script>

<!-- MARK: style -->
<style scoped lang="scss">
@import '@/assets/_colors.scss';

.table {
  // width: 30%;
  // margin-left: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 35px;

  .t-title {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .v-data-table {
    width: 100%;

    .prediction {
      font-size: 12px;
      color: $gray_400;
      text-align: right;
    }
  }
}
</style>
