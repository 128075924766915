<template>
  <div style="width: 70%; margin: auto">
    <apexchart
      height="200px"
      type="line"
      :options="options"
      :series="_series"
    ></apexchart>
  </div>
</template>

<!-- MARK: script -->
<script setup>
import { computed } from 'vue';

const props = defineProps({
  users: Array,
});

const _series = computed(() => {
  const data = [];
  if (props.users) {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth();
    const daysInMonth = new Date(year, month + 1, 0).getDate();

    for (const user of props.users) {
      const rubles = new Array(daysInMonth).fill(0);

      for (const day in user.days)
        rubles[day - 1] = Number(user.days[day].rubles);

      data.push({ name: user.user, data: rubles });
    }
  }

  data.sort(
    (a, b) =>
      b.data.reduce((a, b) => a + b, 0) - a.data.reduce((a, b) => a + b, 0)
  );

  return data;
});

const options = {
  tooltip: {
    enabled: true,
    enabledOnSeries: undefined,
    shared: true,
    followCursor: false,
    intersect: false,
    inverseOrder: false,
    custom: undefined,
    fillSeriesColor: false,
    theme: 'mine',
    style: {
      fontSize: '14px',
      fontFamily: 'Roboto',
    },

    y: {
      formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
        return parseInt(value).toLocaleString('ru-RU');
      },
    },
  },
  title: {
    text: '',
    align: 'center',
    margin: 10,
    offsetX: 0,
    offsetY: 0,
    floating: false,
    style: {
      fontSize: '18px',
      fontWeight: 'bold',
      fontFamily: 'Roboto',
      color: '#e1e3e6',
    },
  },
  colors: ['#E6457A', '#937ff5', '#2EBFE6', '#FFC64D', '#96E946', '#FA71A8'],

  chart: {
    sparkline: {
      enabled: true,
    },
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
    foreColor: '#e1e3e6',
    id: '',
  },
  stroke: {
    curve: 'straight',
    width: 3,
  },
  xaxis: {
    categories: Array.from(
      { length: _series.value[0].data.length },
      (_, i) => i + 1
    ),
    labels: {
      show: false,
    },
  },
  yaxis: {
    labels: {
      show: false,
    },
  },
};
</script>

<!-- MARK: style -->
<style scoped lang="scss"></style>
