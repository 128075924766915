<template>
  <v-app>
    <Loader v-if="!isReady" />
    <LKStats v-else-if="checkUrl('MVS')" />
    <DZ_test v-else-if="checkUrl('DZ_test')" />
    <div v-else>
      <CabinetAdmin v-if="isLogin && user.admin" />
      <Mironov v-else-if="isLogin && user.type == 'mironov'" />
      <Yandex v-else-if="isLogin && user.type == 'yandex'" />
      <Cabinet v-else-if="isLogin" />
      <LogReg v-else />
    </div>
  </v-app>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';

import Mironov from './Mironov/Mironov.vue';
import Yandex from './Yandex/Yandex.vue';

import CabinetAdmin from './Cabinet/CabinetAdmin';
import Cabinet from './Cabinet/Cabinet';
import LogReg from './Log_Reg/Log_Reg';
import Loader from '@/components/res/Loader';

import DZ_test from './Spoof/DZ_test.vue';
import LKStats from './LKStats/LKStats.vue';

export default {
  name: 'Start',

  components: {
    Mironov,
    Yandex,

    Cabinet,
    CabinetAdmin,
    LogReg,
    Loader,
    DZ_test,
    LKStats,
  },

  data() {
    return {};
  },

  computed: {
    ...mapState(['login', 'isLogin', 'isReady', 'user']),
  },

  async beforeMount() {
    if (localStorage.login) {
      await this.signin({
        login: localStorage.login,
        password: localStorage.password,
      });
    }

    this.setIsReady(true);
  },

  methods: {
    ...mapActions(['signin']),
    ...mapMutations(['setIsReady']),

    checkUrl(name) {
      return document.URL.includes(name);
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/_colors.scss';

.main {
  height: 100vh;
  width: 100vw;

  color: $gray_100;
}
</style>
