<template>
  <div v-if="moneyStats">
    <v-snackbar v-model="errorSnack" :timeout="5000" color="error">
      Произошла ошибка на сервере, попробуйте позже.
    </v-snackbar>
    <div class="tables">
      <LKTable :items="moneyDayItems" :title="'За день'" prediction />
      <LKTable
        @change-day="changeTableMoneyDay"
        :items="moneyChosenDayItems"
        :day="tableMoneyDay"
      />
      <LKTable :items="moneyMonthItems" :title="'За месяц'" />
    </div>

    <Chart :users="users" />

    <div class="tables">
      <PlayerTable :items="playersDayItems" :title="'За день'" />
      <PlayerTable :items="playersMonthItems" :title="'За месяц'" />
    </div>
  </div>
</template>

<!-- MARK: script -->
<script setup>
import { onBeforeMount, ref, computed, watch } from 'vue';
import axios from 'axios';

import LKTable from './LKTable.vue';
import PlayerTable from './PlayerTable.vue';
import Chart from './Chart.vue';

const errorSnack = ref(false);
const moneyStats = ref(null);
const playersStats = ref(null);
const tableMoneyDay = ref(new Date(Date.now() - 24 * 60 * 60 * 1000));

const users = computed(() => {
  const keys = Object.keys(moneyStats.value);
  const totalUser = JSON.parse(JSON.stringify(moneyStats.value[keys[0]]));

  totalUser.user = 'Общее';

  const data = Object.assign({}, moneyStats.value);
  delete data[keys[0]];

  for (const user in data) {
    for (const day in data[user].days) {
      if (!totalUser.days[day]) totalUser.days[day] = { rubles: 0 };
      totalUser.days[day].rubles += data[user].days[day]?.rubles;
    }
  }

  return [totalUser, ...moneyStats.value];
});

function sumStats(data, key) {
  return data.reduce((acc, day) => acc + day[key], 0);
}

const moneyMonthItems = computed(() => {
  const data = [];

  for (const user in users.value) {
    const userData = users.value[user];

    const days = Object.values(userData.days);
    const entries = sumStats(days, 'entries');
    const shows = sumStats(days, 'shows');
    const rubles = sumStats(days, 'rubles');

    data.push({ user: userData.user, entries, shows, rubles });
  }

  data.sort((a, b) => b.rubles - a.rubles);

  return data;
});

const moneyDayItems = computed(() => {
  const date = new Date();
  const day = date.getDate();

  return getDayItems(day);
});
const moneyChosenDayItems = computed(() => {
  const date = tableMoneyDay.value;
  const day = date.getDate();

  return getDayItems(day);
});

const getDayItems = day => {
  const data = [];

  for (const user in users.value) {
    const userData = users.value[user];

    const days = userData.days;
    const entries = days[day]?.entries || -1;
    const shows = days[day]?.shows || -1;
    const rubles = days[day]?.rubles || -1;

    data.push({ user: userData.user, entries, shows, rubles });
  }

  data.sort((a, b) => b.rubles - a.rubles);

  return data;
};

const playersMonthItems = computed(() => {
  const data = [];

  for (const player in playersStats.value) {
    const playerData = playersStats.value[player];

    const days = Object.values(playerData.days);
    const entries = sumStats(days, 'entries');
    const shows = sumStats(days, 'shows');

    const recycling = (shows * 100) / entries;

    const playerType = playerData.player.split('_')[0];

    data.push({
      player: playerData.player,
      entries,
      shows,
      recycling,
      playerType,
    });
  }

  data.sort((a, b) => b.shows - a.shows);

  return data;
});

const playersDayItems = computed(() => {
  const date = new Date();
  const day = date.getDate();

  const data = [];

  for (const player in playersStats.value) {
    const playerData = playersStats.value[player];

    const days = playerData.days;
    const entries = days[day]?.entries || -1;
    const shows = days[day]?.shows || -1;

    const recycling = (shows * 100) / entries;
    const playerType = playerData.player.split('_')[0];

    data.push({
      player: playerData.player,
      entries,
      shows,
      recycling,
      playerType,
    });
  }

  data.sort((a, b) => b.shows - a.shows);

  return data;
});

function changeTableMoneyDay(date) {
  tableMoneyDay.value = date;
}

onBeforeMount(async () => {
  try {
    const data = (await axios.get('api/stats/money')).data;
    moneyStats.value = data.moneyData;
    playersStats.value = data.playersData;

    setInterval(async () => {
      const data = (await axios.get('api/stats/money')).data;

      moneyStats.value = data.moneyData;
      playersStats.value = data.playersData;
    }, 30 * 1000);
  } catch (err) {
    errorSnack.value = true;
  }
});
</script>

<!-- MARK: style -->
<style scoped lang="scss">
@import '@/assets/_colors.scss';

.tables {
  margin-top: 50px;
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 50px;
}
</style>
