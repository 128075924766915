<template>
  <div class="table">
    <div>{{ props.title }}</div>
    <v-data-table
      :headers="headers"
      :items="props.items"
      hide-default-footer
      disable-filtering
      disable-pagination
      disable-sort
    >
      <template v-slot:item.entries="{ item }">
        <span>{{ item.entries?.toLocaleString('ru-RU') || -1 }}</span>
      </template>
      <template v-slot:item.shows="{ item }">
        <span>{{ item.shows?.toLocaleString('ru-RU') || -1 }}</span>
      </template>
      <template v-slot:item.recycling="{ item }">
        <span>{{ item.recycling?.toFixed(1) || -1 }}</span>
      </template>
    </v-data-table>
  </div>
</template>

<!-- MARK: script -->
<script setup>
import {} from 'vue';

const props = defineProps({ items: Array, title: String });

const getRecyclingPercentage = item => {
  return (item.shows / item.entries) * 100;
};

const headers = [
  {
    text: 'Плеер',
    value: 'player',
  },

  {
    text: 'Загрузки',
    value: 'entries',
  },
  {
    text: 'Зачеты',
    value: 'shows',
  },
  {
    text: 'Утиль',
    value: 'recycling',
  },
];
</script>

<!-- MARK: style -->
<style scoped lang="scss">
.table {
  // width: 30%;
  // margin-left: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 35px;

  .v-data-table {
    width: 100%;
  }
}
</style>
